(function($) {

	// COUNT
	$('[data-count]').each(function () {
	    $(this).prop('Counter',0).animate({
	        Counter: $(this).text()
	    }, {
	        duration: 5000,
	        easing: 'swing',
	        step: function (now) {
	            $(this).text(Math.ceil(now));
	        }
	    });
	});

	// MENU ON SCROLL
	$(document).on('scroll', function() {
		/* Act on the event */
		if ($(document).scrollTop() > 25) {
			$("[data-menu-fixed]").addClass("fixed");
		} else {
			$("[data-menu-fixed]").removeClass("fixed");
		}
	});

	// HAMBURGER MENU
	$("[data-hamburger]").click(function() {
		/* Act on the event */
		$(this).toggleClass('show');
		$(".hamburger").toggleClass("show");
	});

	// ON SCROLL HIDE
	$(window).scroll(function(e) {
		/* Act on the event */
		if ($(this).scrollTop() > ($("#app").innerHeight())) {
		  	$(".float a").fadeOut();
		} else {
		  	$(".float a").fadeIn();
		}
	});

	// OPEN TAB
	// var url = document.location.toString();
	// if (url.match('#')) {
	//     $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
	// }

	// // Change hash for page-reload
	// $('.nav-tabs a').on('shown.bs.tab', function (e) {
	//     window.location.hash = e.target.hash;
	// });

	// ON SCROLL
	window.Helper = (typeof Helper !== "undefined") ? Helper : {};

	Helper.scroll_to = function(el, top){
		$ = jQuery;
		top = top || 0;

		$('html, body').animate({
	      	scrollTop: $(el).offset().top + top
	  	}, 1500);
	}

	$('[data-scroll-to]').on('click', function(e){ 
		e.preventDefault();
		var $this = $(this);
		$('.main').fadeIn(2000, function() {
		});
		var offset = $($this).data('offset') || 0;
		Helper.scroll_to($($($this).data('href')), offset);
	});
	
})(jQuery);